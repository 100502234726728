import styled from "styled-components";
export const NavBarContainer = styled.div`
width: 100%;
height: 60px;
position: sticky;
top: 0;
z-index: 99;
background:#BDADA0;
`;

export const NavBarWrapper = styled.div`
margin: auto;
width:100%;
height:100%;
align-items: center;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
`;

export const IconLogo = styled.div`
margin-right:10px;
color: #FFFFFF;
float:left;
padding-left: 1rem

`

export const IconLogoMobile = styled.div`
display: none;
@media screen and (max-width: 960px){
    display: flex;
    margin-right:10px;
    color:#FFFFFF;
    float:left;
    padding-left: 2rem;
    font-size: 1.2rem;
 
}
`
export const Menu = styled.ul`
height: 100%;
display:flex;
justify-content: left;
align-items: left;
@media screen and (max-width: 960px){
    width: 50%;
    height: 100vh;
    position: absolute;
    top: 60px;
    left:${({click}) => click ? 0 : "-100%"};
    flex-direction: column;
    transition: 1s all ease-in;
    background-color: #5E4E41;
}
`

export const MenuItem = styled.li`
height:100%;
display: flex;
justify-content: center;
align-items: center;
font-size: 1.2rem;
font-weight: 400;
&:hover {
    background-color:#5E4E41;
    height: 60px;
}

@media screen and (max-width:960px){
    width: 100%;
    height: 60px;
    font-size: 1.2rem;
    display: flex;
   align-items: center;
    
    &:hover {
        background-color:#BDADA0;
    }
}
`