import React, {useState} from "react";
import {Link} from 'react-router-dom';
import './navbar.css';
import './menu.css';
import './fonts.css';
import { IconLogo, NavBarContainer, NavBarWrapper,IconLogoMobile, MenuItem,Menu } from "./NavBar.elements";
import { BsHeartFill,BsHearts } from "react-icons/bs";
import { FaBars,FaHome,FaCat,FaTimes,FaGamepad } from "react-icons/fa";
import { WiStars } from "react-icons/wi";


export default function NavBar(){
    const [click, setClick] = useState(false);
    const changeClick = () => {
        setClick(!click);
    }
    const menuText = "Menu";
    return (
        <div className="div-body">
            <NavBarContainer>
                <NavBarWrapper>
                <header>
                <IconLogoMobile onClick={() => changeClick()}>
                    {click ? <div><FaTimes /> Menu</div> : <div><FaBars /> Menu</div>}
                </IconLogoMobile>
                
                <Menu click = {click}>
                <MenuItem onClick={() => changeClick()}>
                    <Link className="link-a" to="/"><IconLogo> <FaHome /></IconLogo>Home</Link>
                </MenuItem>
                <MenuItem onClick={() => changeClick()}>
                    <Link className="link-a" to="/aniversary"><IconLogo> <BsHearts /></IconLogo>Primer Aniversario</Link>
                </MenuItem>
                <MenuItem onClick={() => changeClick()}>
                    <Link className="link-a" to="/pusheensays"><IconLogo> <BsHeartFill /></IconLogo>Pusheen Says</Link>
                </MenuItem>
                <MenuItem onClick={() => changeClick()}>
                    <Link className="link-a" to="/findpusheen"><IconLogo> <FaGamepad /></IconLogo>Pusheen Game</Link>
                </MenuItem>
                <MenuItem onClick={() => changeClick()}>
                    <Link className="link-a" to="/teadmiromucho"><IconLogo> <FaGamepad /></IconLogo>Te Admiro Mucho</Link>
                </MenuItem>
                <MenuItem onClick={() => changeClick()}>
                    <Link className="link-a" to="/pusheenworks"><IconLogo> <FaCat /></IconLogo>Pusheen Works</Link>
                </MenuItem>
            </Menu>
            
            </header>
    
                </NavBarWrapper>
            </NavBarContainer>
            
            <script src="http://code.jquery.com/jquery-latest.js"></script>
            
        </div>
    );
}