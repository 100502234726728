import React from "react";
import PusheenWorksImg from "./images/pusheen-works.webp";
import "./css/works.css"

export default function PusheenWorks(){
    return (<div>
        
        <section class="works">

            <p>Pusheen is working for more content, please wait... <br /> I love u. <br /></p><br /><br />
            <p><img className="pusheen-works" src={PusheenWorksImg} /></p>
            
        </section>
        </div>);
}
