import React from "react";
import ilovemew from "./images/ilovemew.png";
import yomero from "./images/yomero.jpg";
import './css/teadmiromucho.css'

export default function TeAdmiroMucho(){
    return (
    <div className="body_asombrosa">
        <h1>Eres asombrosa </h1>
            <div className="asombrosa">
                <div class="card">
                    <div class="thumbnail"><img class="left" src={ilovemew}/></div>
                    <div class="right">
                        <h1>Por qué te amo y admiro tanto?</h1>
                        <div class="author"><img src={yomero}/>
                        <h2>Desde el kokoro de Marco</h2>
                        </div>
                        <div class="separator"></div>
                        <p><b>Holi mi niña hermosa, te amo tantisimo, te admiro demasiado y estoy muy orgulloso de ti</b>, ¿por qué? Porque eres lo mejor que 
                            me ha pasado en la vida, eres una persona amable, empática, me cuidas, me amas y siempre buscas como hacerme feliz a pesar
                            de que a veces no te sientas muy bien.
                        </p>
                        <p>Te admiro tanto porque sin importar que pase siempre sigues adelánte, yo sé que este año has pasado por momentos muy difíciles
                            pero apesar de ello, has continuado, aunque has llorado, has sentido que ya no puedes más, no te has rendido, ¿y sabes?
                            yo estoy muy pero muy orgulloso de mi Estelita asombrosa.
                        </p>
                        <p>Siempre siempre te voy a amar mi niña preciosa y siempre vas a contar conmigo para todo mi amor, sin importar qué, yo voy a estar
                            ahí para ti, apoyandote en todo. <b>Te amo demasiado mi Estelita hermosa!</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>);
}
