import React from "react";
import './css/pusheen_says.css';

export default function PusheenSays(){
    return (
    <div>

            <div className="wrap">
                <div className="tarjeta-wrap">
                    <div className="tarjeta">
                        <div className="adelante card1"></div>
                        <div className="atras">
                            <p>Hola mi amor, solo para recordarte que eres una chica asombrosa, eres asombrosa tal cual eres,
                            soy tan afortunado de tenerte en mi vida, me haces demasiado feliz, y no olvides que cuentas conmigo
                            para cualquier cosa, te amo.</p>
                        </div>
                    </div>
                </div>

                <div className="tarjeta-wrap">
                    <div className="tarjeta">
                        <div className="adelante card2"></div>
                        <div className="atras">
                            <p>Holi mi amor, hoy cumplimos 3 meses desde que formalmente somos pareja, no tienes idea de cuan
                            feliz me haz hecho, soy tan feliz y afortunado de tenerte en mi vida, te amo tantisimo Estela, y
                            quiero hacerte muy muy feliz. Te quiero siempre en mi vida amor, nos faltan demasiadas cosas por
                            vivir juntos. <br />Siempre tuyo, Marco.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>);
}
