import React from "react";
import pusheenNotFound from "./images/pusheennotfound.png";

export default function NotFoundPage(){
    return (<div>
        <section class="works">

        <p><b>No se encontró la página...  Error 404 not found<br /> I love u.</b></p>
        <img className="pusheen-works" src={pusheenNotFound} />
        </section>
        </div>);
}
