import React, {useEffect, useState} from "react";
import gameImg from "./images/pusheenGame.jpeg";
import meEncontraste from "./images/meencontraste.gif";
import './css/findPusheen.css';

export default function GamePage(){
    //alert("Encuentra a pusheen dentro de la casa presionando con tu dedito la pantalla, te amo demasido mi Estelita Hermosa <3");
    const [coords, setCoords] = useState({x: 0, y: 0});
    // generate a random Number
    let getRandomNumber = size => {
        return Math.floor(Math.random() * size);
    }
  
    // get the Distance of two points
    let getDistance = (e, target) => {
        let diffX = e.offsetX - target.x;
        let diffY = e.offsetY - target.y;
        return Math.sqrt((diffX * diffX) + (diffY * diffY));
    }
  
    // return an String depending on the distances 
    let getDistanceHint = distance => {
        if (distance < 30) {
        return "Hirbiendo!";
        } else if (distance < 40) {
        return "Muy Caliente";
        } else if (distance < 60) {
        return "Caliente";
        } else if (distance < 100) {
        return "Tibia";
        } else if (distance < 180) {
        return "Fria";
        } else if (distance < 360) {
        return "Muy Fria";
        } else {
        return "Helada";
        }
    }
    // treasure coordinates
    const WIDTH = 600;
    const HEIGH = 365;
    let target = {
        x: getRandomNumber(WIDTH),
        y: getRandomNumber(HEIGH)
      };

      let clicks = 0;
    useEffect(() => {
        const handleWindowMouseMove = event => {
            let $distance = document.querySelector('#distance');
            
            console.log('click');
            clicks++;
            let distance = getDistance(event, target);
            let distanceHint = getDistanceHint(distance);
            $distance.innerHTML = `<h2></br>${distanceHint}</h2>`;
          
            if (distance < 20 ) {
              //alert(`Found the treasure in ${clicks} clicks!`);
              let $mapRemove = document.getElementById("map");;
              $mapRemove.remove();
              $distance.innerHTML = `<h2>Me encontraste!<img src=${meEncontraste} width="450" height="450" align="center" id="map" draggable="false"></img></h2>`;
            }
        setCoords({
            x: event.clientX,
            y: event.clientY,
        });
        };
        let $map = document.querySelector('#map');
        $map.addEventListener('click', handleWindowMouseMove);

        return () => {
            window.removeEventListener(
              'click',
              handleWindowMouseMove,
            );
          };
        }, []);
        
    return (
    <div className="body_div">
        <h2>Encuentra a pusheen dentro de la casa presionando con tu dedito la pantalla, te amo demasido mi Estelita Hermosa</h2>
    <div className="gamebody">
    <p id="distance"></p>
    <img src={gameImg} width="600" height="365" id="map" draggable="false"></img>

    </div>
        </div>);
}
