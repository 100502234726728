import React from "react";
import febrero from "./images/febrero.jpg";
import cositsRicas from "./images/cositas_ricas.jpg";
import pusheen_loveu from "./images/pusheen_loveu.gif";
import besto_pastel from "./images/besto_pastel.jpg";
import junio from "./images/junio.jpg";
import julio from "./images/julio.jpg";
import birthday from "./images/birthday.jpg";
import pusheen_kiss from "./images/pusheen-kiss.gif";
import wedding5 from "./images/wedding5.png";
import fstar from "./images/star.gif";
import puseen_xmas from "./images/pusheen-xmas.jpg";
import nosotros from "./images/nosotros.png";
import './css/aniversary.css'




export default function AniversaryPage(){
    return (
    <div className="body_div">
        <h1>Feliz Aniversario mi amor </h1>
            <div className="timeline">
                <ul>
                    <li>
                        <div className="content">
                            <h3>El mejor mes del año</h3>
                            <p>Justo el primer día de este mes comenzó nuestra relación formalmente, no tienes idea de lo
                            feliz que me hiciste cuando me diste el sí. Me sentí muy feliz tan solo de pensar que mi pareja
                            era una chica tan asombrosa, con la que me sentia tan comodo. Aún recuerdo el: "La tomaré pero
                            me ofende muchisimo" y tú carita shy uwu.</p>
                            <img className="pusheen_text" src={febrero} />
                        </div>
                        <div className="time">
                            <h4>Febrero 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Me encantan los sabados!</h3>
                            <p>Ya llevabamos un mes de relación, y me encantaba que llegara el sabado porque era el día en
                                que nos veiamos, además que siempre paseabamos, nos reiamos muchisimo e ibamos en busca de cosas
                                ricas. Siempre me decia: "Me encantan los sabados".</p>
                            <img className="pusheen_text" src={cositsRicas} />
                        </div>
                        <div className="time">
                            <h4>Marzo 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Abril Eterno :'u</h3>
                            <p>Cumpliamos dos asombrosos meses de relación, y tuvimos que aislarnos, pero eso no evitó que
                            nuestro amor siguiera creciendo, sí, fue dificil estar lejos de ti todo ese tiempo, pero siempre
                            me sentí motivado al saber que pronto volvería a ver a la niña que me hacia muy feliz.
                            Siempre nos marcabamos, haciamos videollamada y seguiamos riendo, y nunca faltaban nuestras cosas
                            raras como la patacion XD. Amo ver y escuchar tu ricita hermosa uwu.</p>
                            <img className="pusheen_text" src={pusheen_loveu} />
                        </div>
                        <div className="time">
                            <h4>Abril 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Se terminará pronto Mayo?</h3>
                            <p>A tres meses de mucha felicidad, aun seguiamos aislados y solo me preguntaba cuando volveré a ver
                            a la niña que amo tanto? Aunque nos marcabamos a diario y platicabamos, te extrañaba muchisimo. A pesar
                            de la distancia me celebraste mi cumpleaños y pasé el mejor cumpleaños de mi vida, pensé: en verdad soy muy
                            afortunado de tenerte en mi vida, a pesar de la distancia me hacias muy feliz dia con dia.</p>
                            <img className="pusheen_text_mayo" src={besto_pastel} />
                        </div>
                        <div className="time">
                            <h4>Mayo 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Nos volvimos a ver uwu</h3>
                            <p>Finalmente nos volvimos a ver, y no cabia de felicidad al volver a verte, fue algo indescriptible
                            el ver a la persona que me hace tan feliz y amo tantisimo. Cada momento a tu lado lo disfruto demasiado
                            soy la persona más feliz de la tierra a tu lado. Te amo demasiado Estela!</p>
                            <img className="pusheen_text" src={junio} />
                        </div>
                        <div className="time">
                            <h4>Junio 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Te amo tantisimo!</h3>
                            <p>Es asombroso como a pesar del aislamiento, nuestra relación iba madurando más y más, y nuestro amor
                            y confianza crecían a diario, amo ver tu carita sonriendo y llena de felicidad. Cada que nos vemos
                            es el mejor día de la vida. (PD. Me planchaste el cabello jajaja.)</p>
                            <img className="pusheen_text" src={julio} />
                        </div>
                        <div className="time">
                            <h4>Julio 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Feliz feliz cumpleaños!</h3>
                            <p>Llegó la mejor fecha del año, la fecha en que se celebra el nacimiento de la persona más importante
                            para mi, el cumpleaños de mi Estelita. Amé ver tu carita de felicidad al partir tu postre de cumpleaños,
                            voy a hacerte feliz en todos los cumpleaños que vienen, eso pensé y es algo que planeo hacer. Muchas
                            gracias por permitirme compartir esos momentos a tu lado. Te amo demasido Estela uwu.</p>
                            <img className="pusheen_text" src={birthday} />
                        </div>
                        <div className="time">
                            <h4>Agosto 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Que rápido pasa el tiempo uwu</h3>
                            <p>7 meses que pasaron demasiado rapido a tu lado cariño, con los cuales nuestro amor y confianza aumentaban
                            y cada día agradecía a Dios y a la vida de que estuvieras a mi lado, y sobre todo a ti por permitirmelo.
                            Sin duda soy alguien demasiado afortunado al tener a una persona tan asombrosa como tú en la vida, muchas
                            gracias por estar en mi vida cariño y apoyarme en todo, te amo!</p>
                            <img className="pusheen_text" src={pusheen_kiss} />
                        </div>
                        <div className="time">
                            <h4>Septiembre 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Se acabó el periodo de prueba XD</h3>
                            <p>Esa frase es algo con lo que bromeabamos XD. Tú eres la persona con la que quiero pasar el resto
                                de mis días y quiero hacerte muy feliz cariño, te amo como no te imaginas Estela!</p>
                            <img className="pusheen_text" src={wedding5} />
                        </div>
                        <div className="time">
                            <h4>Octubre 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Ya casi se termina el año D:</h3>
                            <p>Los meses pasaban y yo cada vez estoy más seguro de que eres la mujer de mis sueños. Sin importar
                            que se nos presentara juntos podemos con todo. Ya llevabamos 9 meses de relación y cada que me mandas un
                            mensaje se me pinta una gran sonrisa en el rostro, día con día, hasta el más minimo detalle o mensajito
                            ilumina la vida, eres la luz de mi vida, Stella di Mars!</p>
                            <img className="pusheen_text" src={fstar} />
                        </div>
                        <div className="time">
                            <h4>Noviembre 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Tiririri tiririri tiriri!!</h3>
                            <p>Qué rápido pasó el año, en este mes fue cuando nos vimos en persona por primera vez, y desde ese momento
                            comenzamos a vernos más a menudo, aún recuerdo nuestras primeras salida, y tú carita sonriente (creo que
                            siempre ame tu sonrisita). Muchas gracias por decidir verme ese día. Te amo muchisimo, mi vida!</p>
                            <img className="pusheen_text" src={puseen_xmas} />
                        </div>
                        <div className="time">
                            <h4>Diciembre 2020</h4>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3>Para siempre siempre</h3>
                            <p>Sin duda alguna nuestro amor crece día con día y nuestra relación se hace más fuerte, solo me queda
                            decir que te amo como no te imaginas Estela, soy demasiado afortunado por tenerte en mi vida, no sabes
                            lo feliz que soy al estar a tu lado. Me voy a esforzar por hacerte muy feliz el resto de mi vida y
                            que sientas toda la felicidad que siento en estos momentos y más, mereces toda la felicidad de la vida,
                            amor mio. Somos el besto equipo de la vida, y juntos podremos con lo que sea, sabes que siempre vas a
                            contar con mi apoyo. Ya planeamos la bodación y nuestra habitación, aún faltan muchas más cosas por
                            planear y por hacer, y aún crecerá mucho más nuestro amor y felicidad. Te amo demasiado Estela.
                            PD. Siempre tuyo, mi amor, para siempre siempre</p>
                            <img className="pusheen_text" src={nosotros} />
                        </div>
                        <div className="time">
                            <h4>Enero 2021</h4>
                        </div>
                    </li>
                </ul>
            </div>
        </div>);
}
