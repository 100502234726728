import React from "react";
import './css/home.css';
import pusheenHug from './images/pusheenHug.png';
import pusheenDraw from './images/pusheenDraw.jpg';

export default function HomePage(){
    return (
    <div>
        <section className="secionindex">
        <table className="tableIndex">
            <tbody>
                <tr>
                    <td><img className="pusheen" src={pusheenHug}/></td>
                    <td>
                    <p className="teamo"><b>Holi mi amor, te mando un abracito virtual, </b></p>

                    <p className="teamo"><b>y recuerda que no est&aacute;s solita, siempre estoy para ti, </b></p>

                    <p className="teamo"><b>juntos podemos afrontar lo que sea mi vida. </b></p>

                    <p className="teamo"><b>Te amo demasiado Mi ni&ntilde;a hermosa, no te imaginas cuanto, </b></p>

                    <p className="teamo"><b>y eres lo m&aacute;s importante de mi vida.</b></p>

                    <p className="teamo"><br />
                    <b>Siempre tuyo: Marco uwu</b></p>
                    </td>
                </tr>
            </tbody>
        </table>

        <hr />
        <table className="tableIndex">
            <tbody>
                <tr>
                    <td><img className="pusheen" src={pusheenDraw} /></td>
                    <td>
                    <p className="teamo"><b>Sending a virtual hug for Stella<br />
                    <br />
                    You are not alone :)</b></p>
                    </td>
                </tr>
            </tbody>
        </table>
        </section>
    </div>
        );
}
