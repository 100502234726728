import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import AniversaryPage from "./pages/AniversaryPage";
import NotFoundPage from "./pages/NotFoundPage";
import NavBar from "./components/NavBar";
import PusheenSays from "./pages/PusheenSays";
import PusheenWorks from "./pages/PusheenWorks";
import FindPusheen from "./pages/FindPusheen";
import TeAdmiroMucho from "./pages/TeAdmiroMucho";

export default function App(){
  return(
    <BrowserRouter>
      <NavBar/>
      <Routes>
      <Route path='/' element={<HomePage/>}></Route>
      <Route path='/aniversary' element={<AniversaryPage/>}></Route>
      <Route path='/pusheensays' element={<PusheenSays/>}></Route>
      <Route path='/pusheenworks' element={<PusheenWorks/>}></Route>
      <Route path='/findpusheen' element={<FindPusheen/>}></Route>
      <Route path='/teadmiromucho' element={<TeAdmiroMucho/>}></Route>
      <Route path='*' element={<NotFoundPage/>}></Route>
      </Routes>
  </BrowserRouter>
  )
}